<template>
	<h1 class="logo text-center" v-bind="$attrs">
		<svg :width="width" :alt="title" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514.000000 50.000000" preserveAspectRatio="xMidYMid meet">
			<g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
				<path d="M1412 442 c-29 -11 -64 -30 -79 -43 -56 -52 -78 -145 -49 -212 21 -51 90 -104 151 -118 75 -17 200 -7 251 19 147 76 148 264 2 338 -72 36 -198 43 -276 16z m159 -52 c65 -35 66 -224 1 -259 -81 -43 -153 63 -122 178 20 74 70 108 121 81z"></path>
				<path d="M4735 451 c-51 -13 -112 -49 -133 -78 -64 -90 -43 -208 48 -270 46	-32 93 -43 181 -43 170 0 268 82 257 216 -9 109 -99 176 -242 180 -45 2 -94 0	-111 -5z m138 -72 c54 -42 59 -171 10 -230 -21 -25 -32 -30 -62 -27 -51 4 -81	51 -81 129 0 67 10 99 41 128 29 27 59 27 92 0z"></path>
				<path d="M80 420 c0 -29 2 -30 43 -30 l43 0 85 -160 84 -160 102 0 102 0 83	160 83 160 43 0 c40 0 42 1 42 30 l0 30 -125 0 -125 0 0 -30 c0 -27 3 -30 30	-30 17 0 30 -2 30 -5 0 -3 -25 -55 -56 -117 l-56 -111 -30 54 c-40 73 -88 167	-88 174 0 3 18 5 40 5 38 0 40 2 40 30 l0 30 -185 0 -185 0 0 -30z"></path>
				<path d="M2360 420 c0 -29 2 -30 45 -30 l45 0 0 -130 0 -130 -45 0 c-43 0 -45 -1 -45 -30 l0 -30 260 0 261 0 -3 88 c-3 85 -4 87 -29 90 -22 3 -27 -2 -38 -38 -16 -55 -57 -80 -133 -80 l-58 0 0 130 0 130 40 0 c38 0 40 2 40 30 l0 30 -170 0 -170 0 0 -30z"></path>
				<path d="M3370 421 c0 -28 2 -30 46 -33 l46 -3 82 -155 83 -155 104 -3 104 -2	81 157 80 158 45 5 c39 4 44 8 47 33 l3 27 -130 0 -131 0 0 -30 c0 -28 3 -30 36 -30 l35 0 -57 -110 c-31 -61 -60 -110 -65 -110 -5 0 -92 156 -117 212 -2 4	16 8 40 10 39 3 43 6 46 31 l3 27 -190 0 -191 0 0 -29z"></path>
			</g>
		</svg>
	</h1>
</template>

<script>
export default {
	name: 'Logo',
	props: {
		width: {
			type: String,
			default: '170px'
		},
		title: {
			type: String,
			default: 'Volvo'
		}
	}
}
</script>
